import React, { useRef, useEffect } from 'react'

const KEYCODE_TAB = 9

function useFocusTrap() {
  const elRef = useRef(null)

  function handleFocus(e: {
    key: string
    keyCode: number
    shiftKey: any
    preventDefault: () => void
  }) {
    const focusableEls = elRef.current?.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      ),
      firstFocusableEl = focusableEls[0],
      lastFocusableEl = focusableEls[focusableEls.length - 1]

    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    elRef.current?.addEventListener('keydown', handleFocus)

    return _ => {
      elRef.current?.removeEventListener('keydown', handleFocus)
    }
  }, [])

  return elRef
}

export default function FocusTrap(props) {
  const elRef = useFocusTrap()

  return (
    <div className={'trap'} ref={elRef}>
      {props.children}
    </div>
  )
}
