import React, { useState, useRef } from 'react'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { graphql } from 'gatsby'
import { useForm } from 'react-hook-form'
import { CustomerReferenceGroupFormQuery } from '../../types/graphql-types'
import { Input, Select, ErrorMessage } from '../styles/form-styles'
import ContentfulRichText from '../components/ContentfulRichText'
import useOnClickOutside from '../hooks/useClickOutside'
import Modal from '../components/Modals/index'
import axios from 'axios'
import LayoutMinimal from '../components/Layout/Minimal'

interface Props {
  data: CustomerReferenceGroupFormQuery
}

type Inputs = {
  CustomerName: string
  Email: string
  Product: string
  Date: string
  TermsAccepted: boolean
  Text2: string
  submit: { message: string }
}

const CustomerReferenceGroupForm = styled.form`
  ${tw`text-lg`}

  button[type='submit'][disabled] {
    ${tw`cursor-not-allowed opacity-50`}
  }
`

const HP = styled.div`
  background-color: transparent;
  color: transparent;
  pointer-events: none;
  position: fixed;
  top: -1000000000px;
`

export const query = graphql`
  query CustomerReferenceGroupForm {
    contentfulPage(
      externalName: { eq: "Customer Interview Agreement Form Page" }
    ) {
      id
      title
      externalName
      slug
      seoTitle
      seoDescription
      noindex
      content {
        raw
      }
    }
  }
`
const getApi = () => {
  return `/myaccount/api/customerreferencegroupform`
}

const CustomerReferenceGroupFormPage = ({ data }: Props): JSX.Element => {
  const refCloseModal = useRef()

  const [submitted, setSubmitted] = useState(false)
  const [checked, setChecked] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<Inputs>()
  // Using this to test fields, Will remove this once we are hooked up with email Service

  const [isModalActive, setIsModalActive] = useState(false)

  const toggleModal = () => {
    setIsModalActive(!isModalActive)
    reset()
  }

  const closeModal = () => {
    setIsModalActive(false)
    reset()
  }

  useOnClickOutside(refCloseModal, closeModal)

  const onSubmit = async (data: Inputs) => {
    axios({
      url: getApi(),
      method: 'post',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      data: JSON.stringify(data),
    })
      .then(response => {
        console.log(response)
        setSubmitted(true)
        reset()
      })
      .catch(error => {
        setError('submit', {
          type: 'server',
          message: `We’re sorry, but we’re currently experiencing temporary technical difficulties. Please either try again later or call Customer Service at 800-733-6632.`,
        })
        setIsModalActive(true)
        console.log(error)
      })
  }

  const showThankYou = (
    <div className="msg-confirm">
      <h2 className="mb-8">Thank You!</h2>
      <p className="text-2xl">We will reach out to discuss the product soon.</p>
    </div>
  )

  const showForm = (
    <CustomerReferenceGroupForm
      data-analytics-form="Customer Reference Group Form"
      onSubmit={handleSubmit(onSubmit)}
      method="post"
    >
      <p>
        <strong>
          Fill in your name at the top, and complete the form at the bottom.
        </strong>
      </p>
      <p className="mb-8">
        This Customer Reference Group Agreement (“Agreement”), is made and
        entered into as of the Effective Date by and between{' '}
        <label className="sr-only" htmlFor="CustomerName">
          Customer Name*
        </label>
        <input
          className="w-full border-b-2 border-gray-800 bg-yellow-100 sm:w-2/5"
          id="CustomerName"
          placeholder="Please type full name here."
          type="text"
          {...register('CustomerName', { required: true })}
          disabled={isSubmitting}
        />
        {errors.CustomerName && (
          <ErrorMessage>
            <b> *required </b>
          </ErrorMessage>
        )}
        (“Participant”) and Best Buy Health, Inc. doing business as Lively
        (“Company”).
      </p>
      {data.contentfulPage?.content !== null && (
        <ContentfulRichText document={data.contentfulPage?.content} />
      )}
      <div className="md:grid md:grid-cols-2 md:gap-4">
        <div className="mb-4">
          <label htmlFor="Email">Email Address*</label>
          <Input
            type="Email"
            id="Email"
            className="bg-yellow-100"
            placeholder="johndoe@gmail.com"
            {...register('Email', {
              required: true,
              pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
            })}
            disabled={isSubmitting}
          />
          {errors.Email?.type === 'required' && (
            <ErrorMessage> *required </ErrorMessage>
          )}
          {errors.Email?.type === 'pattern' && (
            <ErrorMessage> Please enter a valid email </ErrorMessage>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="Product">Which product do you use?*</label>
          <Select
            id="Product"
            className="bg-yellow-100"
            {...register('Product', { required: true })}
            disabled={isSubmitting}
          >
            <option value="">Please select</option>
            <option value="Flip Phone">Flip Phone</option>
            <option value="Smart Phone">Smart Phone</option>
            <option value="Link">Link</option>
            <option value="Lively Mobile">Lively Mobile</option>
            <option value="Lively Wearable">Lively Wearable</option>
          </Select>
          {errors.Product && <ErrorMessage> *required </ErrorMessage>}
        </div>
        <div className="mb-4">
          <label htmlFor="Date">Date*</label>
          <Input
            type="text"
            id="Date"
            className="mb-2 bg-gray-200"
            value={new Date().toLocaleDateString('en-US')}
            {...register('Date', { required: true })}
            disabled={isSubmitting}
          />
          {errors.Date && <ErrorMessage> *required </ErrorMessage>}
        </div>
        <HP>
          <label htmlFor="Text2">Please leave this field blank</label>
          <Input
            type="text"
            id="Text2"
            autoComplete="off"
            {...register('Text2')}
            disabled={isSubmitting}
          />
        </HP>
        <div className="mb-4 sm:col-span-2">
          <label htmlFor="TermsAccepted" className="bg-yellow-100">
            <input
              type="checkbox"
              id="TermsAccepted"
              checked={checked}
              {...register('TermsAccepted', {
                required: true,
                onChange: e => {
                  setChecked(e.target.checked)
                },
              })}
              className="mr-4 cursor-pointer"
            />
            I accept the terms of this Agreement
          </label>
          {errors.TermsAccepted && <ErrorMessage> *required </ErrorMessage>}
        </div>
        <div className="sm:col-span-2">
          <button
            type="submit"
            className="btn-primary mb-6 w-full md:w-auto"
            disabled={isSubmitting || isSubmitSuccessful}
            data-analytics-button="Customer Interview Agreement Form Submit"
          >
            Submit
          </button>
        </div>
      </div>
    </CustomerReferenceGroupForm>
  )

  return (
    <LayoutMinimal>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />
      <section className="content-section pt-8">
        <div className="container">
          <h1 className="mb-8 leading-none">Customer Interview Agreement</h1>
          {submitted ? showThankYou : showForm}
        </div>
      </section>
      {errors && errors.submit && (
        <Modal
          ref={refCloseModal}
          content={errors.submit.message as unknown as string}
          isActive={isModalActive}
          toggleModal={toggleModal}
          modalId={''}
          ariaLabelledBy={''}
          labelId={''}
        />
      )}
    </LayoutMinimal>
  )
}

export default CustomerReferenceGroupFormPage
